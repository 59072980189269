<template>
  <b-row>
    <b-col md="12">
      <!-- Search Input Group with Responsive Layout -->
      <div class="search-wrapper">
        <b-input-group class="mb-1 search-input-group flex-wrap">
          <!-- Search Input with Idea Icon -->
          <div class="position-relative w-100 mb-1 mb-md-0">
            <b-form-input 
              class="search-input pr-4" 
              placeholder="Enter your prompt in details to start generating... " 
              v-model="searchQuery"
            ></b-form-input>
            <div 
              class="idea-icon-wrapper"
              @click="suggestPrompt"
              v-b-tooltip.hover.right
              title="Get prompt suggestion"
            >
              <img 
                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWxpZ2h0YnVsYiI+PHBhdGggZD0iTTE1IDE0Yy4yLTEgLjctMS43IDEuNS0yLjUgMS0uOSAxLjUtMi4yIDEuNS0zLjVBNiA2IDAgMCAwIDYgOGMwIDEgLjIgMi4yIDEuNSAzLjUuNy43IDEuMyAxLjUgMS41IDIuNSIvPjxwYXRoIGQ9Ik05IDE4aDYiLz48cGF0aCBkPSJNMTAgMjJoNCIvPjwvc3ZnPg=="
                alt="Idea icon"
                class="idea-icon"
              />
            </div>
          </div>
          
          <!-- Buttons Group -->
          <div class="button-wrapper w-100 w-md-auto d-flex">
            <b-input-group-append class="w-100 w-md-auto d-flex">
              <b-button 
                variant="primary" 
                @click="searchInput" 
                :disabled="generating" 
                class="flex-grow-1 flex-md-grow-0 button-margin me-2"
              >
                <feather-icon size="13" icon="ZapIcon" />
                {{ generating ? 'Generating' : 'Generate' }}
              </b-button>
              <b-button 
                variant="secondary" 
                @click="resetSeed"
                class="flex-grow-0"
              >
                <feather-icon size="13" icon="RefreshCwIcon" />
              </b-button>
            </b-input-group-append>
          </div>
        </b-input-group>
      </div>

      <!-- Info Line -->
      <div
        v-if="generating"
        class="info-line d-flex align-items-center justify-content-between flex-wrap mt-2"
      >
        <div class="d-flex align-items-center flex-grow-1">
          <feather-icon icon="InfoIcon" class="info-icon me-2" />
          <span class="note-text">
            Note: Some images may be inaccurate. Please click 
            <feather-icon icon="HeartIcon" class="like-icon" /> 
            on the best images to save them and recreate similar one later on.
          </span>
        </div>
        <b-button
          variant="link"
          @click="hideInfoLine"
          class="close-button ms-2"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>

      <!-- Swiper Component -->
      <swiper 
        v-if="showSwiper" 
        :options="swiperOptions" 
        class="swiper-container mt-1"
      >
        <swiper-slide 
          v-for="(tab, index) in tabs" 
          :key="index" 
          class="ss-width"
        >
          <b-button 
            :variant="tab.active ? 'dark' : 'outline-secondary'" 
            @click="selectTab(index)" 
            class="tab-button"
          >
            {{ tab.name }}
          </b-button>
        </swiper-slide>
      </swiper>

    </b-col>
  </b-row>
</template>


<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { BFormSelect, Btooltip } from 'bootstrap-vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { EventBus } from '@/event-bus';

export default {
  props: {
    showSwiper: {
      type: Boolean,
      default: true, // Swiper is shown by default
    },
    prompts: { // Accept prompts as a prop
      type: Array,
      default: () => [],
    },
  },
  directives: {
    'b-tooltip': Btooltip
  },
  components: {
    Swiper,
    SwiperSlide,
    BFormSelect,
    ToastificationContent,
    Btooltip
  },
  data() {
    return {
      searchQuery: '',
      selectedImageType: 'illustrations', // Default image type
      imageTypes: [
        { value: 'images', text: 'Images' },
        { value: 'memes', text: 'Memes' },
        { value: 'quotes', text: 'Quotes' },
        { value: 'illustrations', text: 'Illustrations' }
      ],
      tabs: [
        { name: 'Comic book', active: true },
        { name: 'Pixar/Disney Character', active: false },
        { name: 'Line art', active: false },
        { name: 'Black and White Film Noir', active: false },
        { name: 'Japanese Anime', active: false },
        { name: 'Digital/Oil Painting', active: false },
        // { name: 'Photographic', active: false },
        { name: 'Isometric Rooms', active: false },
      ],
      generating: false, 
      showInfoLine: false, 
      settings: {
                maxScrollbarLength: 100,
                wheelSpeed: .60
            },
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
                    768: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
                    640: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
          320: {
            slidesPerView: 'auto',
            spaceBetween: 5,
          },
        },
      },
      selectedTab: 'Comic book',
      requestCount: 0, 
      maxRequests: 3, 
      isLogged: false, 
      currentPromptIndex: 0,
      currentSeed: '',
    };
  },

  watch: {
    $route(to) {
      this.updateSelectedImageType(to);
    }
  },
  mounted() {
    this.updateSelectedImageType(this.$route); // Call this on mount to set the initial type
  },

  created() {

this.checkUserLoggedIn();
EventBus.$on('update-generating', this.updateGenerating);
  // Auto-select the image type based on the route
  const currentRoute = this.$route.path;
  if (currentRoute.includes('/agents/images')) {
    this.selectedImageType = 'images';
  } else if (currentRoute.includes('/agents/memes')) {
    this.selectedImageType = 'memes';
  } else if (currentRoute.includes('/agents/quotes')) { 
    this.selectedImageType = 'quotes';
  }

},
computed: {

        isLogg() {
            return this.$store.state.app.isLogged
    },
  },
  methods: {
    checkUserLoggedIn() {
            const userData = localStorage.getItem("userData")

            if (userData == null || undefined) {
                this.$store.commit("app/SET_USER", false);

            } else {
                this.$store.commit("app/SET_USER", true);
            }

        },
        searchInput() {
      if (!this.searchQuery) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'XIcon',
            title: 'Input Required',
            text: 'Please enter a prompt before generating.',
            variant: 'danger',
          },

        });
        return;
      }
      if (!this.isLogg && this.requestCount >= this.maxRequests) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'XIcon',
            title: 'Limit Reached',
            text: 'You have reached the maximum number of requests. Please log in to continue.',
            variant: 'danger',
          },
        });
        this.openLoginModal();
      } else {
        this.generating = true; 
        this.showInfoLine = true;
        EventBus.$emit('generate-images', this.searchQuery, this.selectedTab, this.selectedImageType);
        if (!this.isLogg) {
          this.requestCount++;
        }
      }
    },
    resetSeed() {
    this.$bvModal.msgBoxConfirm('Are you sure you want to refresh? This will generate new variations.')
      .then(confirmed => {
        if (confirmed) {
          this.seed = null;
          localStorage.removeItem('savedSeed');
          this.showToast('Seed has been refreshed. New images will have a different variation.', 'info');
        }
      })
      .catch(err => {
        // Handle any errors here
      });
  },
  suggestPrompt() {
      if (!this.prompts.length) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'InfoIcon',
            title: 'No Prompts Available',
            text: 'There are no prompts available to suggest.',
            variant: 'info',
          },
        });
        return;
      }

      // Fetch the current prompt
      const prompt = this.prompts[this.currentPromptIndex];
      const currentPromptData = this.prompts[this.currentPromptIndex];
      // Set the searchQuery to the current prompt
      this.searchQuery = currentPromptData.prompt;
      this.savedSeed = currentPromptData.seed;
      localStorage.setItem('savedSeed', this.savedSeed);

      // Increment the prompt index for next suggestion
      this.currentPromptIndex = (this.currentPromptIndex + 1) % this.prompts.length;

      // Optionally, immediately trigger the search
      // this.searchInput();
    },

    updateGenerating(status) {
      this.generating = status;
    },
    selectTab(index) {
      this.tabs.forEach((tab, i) => {
        tab.active = i === index;
        if (tab.active) {
          this.selectedTab = tab.name;
        }
      });
      this.$emit('update:imageStyle', this.selectedTab); // Emit the selected tab name
    },
    openLoginModal() {
      this.$bvModal.show('modal-login');
    },
    handleLogin() {
      this.$store.commit("app/SET_USER", true);
      this.requestCount = 0; // Reset the request count on login
    },
    hideInfoLine() {
      this.showInfoLine = false; // Hide the info line when close button is clicked
    },
    updateSelectedImageType(route) {
      if (route.path.includes('/agent/images')) {
        this.selectedImageType = 'images';
      } else if (route.path.includes('/agent/memes')) {
        this.selectedImageType = 'memes';
      }
     else if (route.path.includes('/agent/illustrations')) {
        this.selectedImageType = 'illustrations';
      }
      else if (route.path.includes('/agent/quotes')) {
        this.selectedImageType = 'quotes';
      }
    }
  },
};
</script>

<style scoped>
/* Adjust styles for a more compact layout */
.search-wrapper {
  width: 100%;
}
.idea-icon-wrapper {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.idea-icon-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.idea-icon {
  width: 20px;
  height: 20px;
  color: #17007d;
  transition: all 0.3s ease;
  filter: opacity(0.6);
}

.idea-icon-wrapper:hover .idea-icon {
  filter: opacity(1);
}

.search-input {
  min-height: 40px;
}

.button-wrapper {
  display: flex;
  align-items: stretch;
  padding-top: 10px;
  padding-bottom: 0px;
}

.image-type-select {
  max-width: 100px; /* Adjust width as needed */
  margin-left: 10px; /* Add margin for better spacing */
}

.swiper-slide {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    padding: auto !important; /* Adds padding inside each slide for spacing */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.swiper-container {
    width: 100%;            /* Ensures the swiper takes full width */
    overflow-x: auto;       /* Enables horizontal scrolling for the container */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    scrollbar-width: none;  /* Hides scrollbar in Firefox */
    -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.swiper-container::-webkit-scrollbar {
    display: none;  /* Hides scrollbar in WebKit browsers */
}

.button-margin{
  margin-right: 5px;
  margin: 0px;
}

.tab-button {
  font-size: 1rem !important;
  font-variant: Black !important;
    margin-bottom: 8px; /* Space between tags if they wrap to the next line */
    margin-top: 2px; 
    margin-left: 4px; 
    margin-right: 4px; 
    padding: 0.48571rem 0.71429rem !important;
    display: inline-flex; /* Use inline-flex to maintain inline behavior with flexbox capabilities */
    align-items: center !important;
    justify-content: center !important;
    white-space: nowrap; /* Keeps the tag text in a single line */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
    overflow: hidden;
    border-radius: 20px; /* Rounded corners for aesthetic */
    border: 1px solid #ccc; /* Subtle border for definition */
    background-color: hsl(0, 0%, 100%); /* Background color */
    transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
}

.tab-button:hover, .tab-button.is-active {
    background-color: #0069d9; 
}

.b-button {
  border-radius: 0.25rem; /* Match button styles as per your design */
}
.ss-width {
    width: auto !important;

}
.form-control{
  border: 1px solid #212121
}
.custom-select{
  border: 1px solid #212121
}
.search-input::placeholder {
  color: rgb(141, 141, 141); /* Change this to your desired dark grey color */
}
.info-line {
  /* border-top: 1px solid #e0e0e0; */
  /* padding-bottom: 8px; */
  margin-top: -2px;
  margin-bottom: 12px;
}

.info-icon {
  color: #4d4d4d; /* Bootstrap info color */
}

.close-button {
  /* Remove default button styles */
  padding: 0;
  margin-left: auto;
  color: #6c6c6c;
}

.close-button:hover {
  color: #343a40; /* Darken color on hover */
}

.close-button .feather-icon {
  width: 15px;
  height: 15px;
}

.note-text {
  color: #5a5a5a; /* Bootstrap secondary text color */
  font-size: .95rem;
  padding-left: 3px;
}

/* Optional: Adjust the Info icon size and alignment */
.info-icon {
  width: 14px;
  height: 14px;
}

.like-icon {
  color: red;
  fill: red;
  margin-bottom: 2px;
}

@media (max-width: 768px) {
  
  .image-type-select{
    max-width: 30px;
}
.custom-select{
  border: 1px solid #212121
}
.form-control{
  border: 1px solid #212121;
}
.seed-controls {
  margin-top: 0em;
  margin-bottom: 1em;
  margin-left: 2px;
  display: flex;
  align-items: center;
}

.button-wrapper {
  display: flex;
  align-items: stretch;
  padding-top: 0px;
  padding-bottom: 0px;
}

.seed-controls .seed-info {
  margin-left: 1em;
  font-weight: bold;
}
.tab-button {
  font-size: 1rem !important;
    margin-bottom: 8px; /* Space between tags if they wrap to the next line */
    margin-top: 2px; 
    margin-left: 4px; 
    margin-right: 4px; 
    padding: 0.48571rem 0.71429rem !important;
    display: inline-flex; /* Use inline-flex to maintain inline behavior with flexbox capabilities */
    align-items: center !important;
    justify-content: center !important;
    white-space: nowrap; /* Keeps the tag text in a single line */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
    overflow: hidden;
    border-radius: 20px; /* Rounded corners for aesthetic */
    border: 1px solid #ccc; /* Subtle border for definition */
    background-color: hsl(0, 0%, 100%); /* Background color */
    transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
}
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~swiper/css/swiper.css';
</style>
