<template>
    <b-row class="match-height mt-md-1 mt-0">
      <image-card v-for="product in products" :key="product.id" :product="product" @icon-click="handleIconClick" @toggle-like="toggleLike" @buy-product="buyProduct" />
    </b-row>
  </template>
  
  <script>
  import ImageCard from './imageCard.vue'; // Ensure this matches the exact case
  
  export default {
    components: {
      ImageCard,
    },
    props: {
      products: {
        type: Array,
        required: true,
      },
    },
    methods: {
      handleIconClick(product, action) {
        this.$emit('icon-click', product, action);
      },
      toggleLike(product, status) {
        this.$emit('toggle-like', product, status);
      },
      buyProduct(url) {
        this.$emit('buy-product', url);
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>  